import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import PropTypes from "prop-types";

import { appUrls, outerUrls } from "../../urls";
import { useFormikContext } from "formik";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../../components/Input";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import Entry from "../../layouts/Entry";

import { useDispatch } from "react-redux";
import styles from "./ResetPassword.module.scss";
import { resetPasswordToken } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

export const ErrorListener = ({ onError }) => {
  const formik = useFormikContext();

  useEffect(() => {
    onError(formik.errors);
    // eslint-disable-next-line
  }, [formik.errors]);

  return null;
};

const useToken = (name) => {
  const { pathname } = useLocation();

  return pathname.split("/").pop();
};

const ResetPassword = () => {
  const { t, language, changeLanguage } = useContext(I18nContext);
  const dispatch = useDispatch();
  const token = useToken("token");

  const searchParams = new URLSearchParams(document.location.search);
  const languageCode = searchParams.get("languageCode");

  const [hasError, setHasError] = useState();
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isPasswordConfirmationShown, setPasswordConfirmationShown] =
    useState(false);

  useEffect(() => {
    if (!!languageCode && language !== languageCode) {
      changeLanguage(languageCode);
    }
  }, []);

  const handlePasswordVisibility = () => {
    setPasswordShown(!isPasswordShown);
  };

  const handlePasswordConfirmationVisibility = () => {
    setPasswordConfirmationShown(!isPasswordConfirmationShown);
  };
  const initialValues = {
    password: "",
    passwordConfirmation: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t("enter password"))
      .min(8, t("password must contain at least 8 characters"))
      .matches(/[a-z]/, {
        message: t("password must contain at least one lowercase character"),
      })
      .matches(/[A-Z]/, {
        message: t("password must contain at least one uppercase character"),
      })
      .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, {
        message: t(
          "password must contain at least 1 number or special character"
        ),
      })
      .max(20, t("password can contain up to 20 characters")),
    passwordConfirmation: Yup.string()
      .required(t("confirm your password"))
      .oneOf([Yup.ref("password"), null], t("passwords must match")),
  });

  const handleSubmit = ({ password }) => {
    dispatch(resetPasswordToken(t, token, password));
  };

  return (
    <Entry>
      <div className={styles.container}>
        {!hasError ? (
          <span className={styles.icon_circle}>
            <Icon className={styles.icon} name="padlock" />
          </span>
        ) : (
          <span className={styles.icon_circleRed}>
            <Icon className={styles.icon} name="warning" />
          </span>
        )}
        <span className={styles.header}>{t("reset password")}</span>
        <span className={styles.txt_container}>
          {t("please enter and confirm your new password")}
        </span>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <div className={styles.password_info}>
            {t(
              "password must consist of 8-20 characters and include at lest one lowercase character, one uppercase character and 1 number or special character"
            )}
            .
          </div>
          <div className={styles.password_wrapper}>
            <FormControl>
              <FieldLabel>
                {t("password")}
                <span className={styles.red}>*</span>
              </FieldLabel>
              <FieldInput
                name="password"
                autoComplete="current-password"
                type={isPasswordShown ? "text" : "password"}
                data-testid="password"
              />
              <FieldError name="password" />
              <div
                className={styles.show_password}
                onClick={handlePasswordVisibility}
              >
                {isPasswordShown ? (
                  <div className={styles.txt_show}>HIDE</div>
                ) : (
                  <div className={styles.txt_show}>SHOW</div>
                )}
              </div>
            </FormControl>
          </div>
          <ErrorListener onError={(errors) => setHasError(!!errors.email)} />
          <div className={styles.password_wrapper}>
            <FormControl>
              <FieldLabel>
                {t("password confirmation")}
                <span className={styles.red}>*</span>
              </FieldLabel>
              <FieldInput
                name="passwordConfirmation"
                type={isPasswordConfirmationShown ? "text" : "password"}
              />
              <FieldError name="passwordConfirmation" />
              <div
                className={styles.show_password}
                onClick={handlePasswordConfirmationVisibility}
              >
                {isPasswordConfirmationShown ? (
                  <div className={styles.txt_show}>HIDE</div>
                ) : (
                  <div className={styles.txt_show}>SHOW</div>
                )}
              </div>
            </FormControl>
          </div>
          <ErrorListener onError={(errors) => setHasError(!!errors.email)} />
          <Button
            className={styles.btn_separator}
            type="submit"
            variant="orange"
            size="l"
          >
            {t("confirm")}
          </Button>
          <div className={styles.contact_container}>
            <span className={styles.txt_account}>
              {`${t("don’t have an account")}?`}
            </span>
            <a
              className={styles.link_account}
              href={outerUrls.CONTACT_US}
              target="_blank"
              rel="noreferrer"
            >
              {t("contact us")}
            </a>
          </div>
        </Form>
        <Link className={styles.link_return} to={appUrls.LOGIN}>
          {t("back to login")}
        </Link>
      </div>
    </Entry>
  );
};
ResetPassword.propTypes = {
  user: PropTypes.object,
};

export default ResetPassword;
